// src/LandingPage.js
import React from 'react';
import '../stylesheets/Landing.css';
import accentureLanding from '../assets/accenture-landing.png'
import changeLanding from '../assets/change-landing.png'

const LandingPage = () => {
  return (
    <div className="landing-page">
      <img src={accentureLanding} alt="Logo" className="logo accenture-logo" />
      <img src={changeLanding} alt="Change" className="logo change-logo" />
      </div>
  );
};

export default LandingPage;
