// NavBar.js
import React from 'react';
import group from '../assets/navbar/pga_banner.png';
import Vector from '../assets/navbar/Vector.svg';

function NavBar({ isMobile, pagetype }) {
  const windowWidth = window.innerWidth;
  console.log(windowWidth);
  console.log(pagetype)

  // Construct the dynamic class name based on pagetype
  const navbarClass = `navbar-bg ${pagetype}-class`;

  return (
    <div className={navbarClass}>

    </div>
  );
}

export default NavBar;
