import React from 'react';
import qr from '../assets/qr.svg'; // Adjust the path as needed
import apple from '../assets/apple.svg'; // Adjust the path as needed
import android from '../assets/android.svg'; // Adjust the path as needed
import weather from '../assets/Group 46.svg'
import apple_appstore from '../assets/apple.png';
import android_appstore from '../assets/android.png';
import acn from '../assets/navbar/acn.svg';
import footer from '../assets/navbar/footer.png'
import amplifyconfig from '../amplifyconfiguration.json';
import { enable, disable, record, configureAutoTrack } from 'aws-amplify/analytics';
import { Amplify } from "aws-amplify";

Amplify.configure(amplifyconfig)

configureAutoTrack({
  enable: true,
  type: 'session',
});

//Record an event
record({
  name: 'csuite-session'
});


function Subpage1({ mobile, mobileType, phoneType, changePhone }) {
    return (
        <div>

        <div className='see-soon'>
          <div className='see-soon-inner'>
            <span className='see-soon-title'>SEE YOU SOON!</span >
            <p className='see-soon-subtitle'>
            We are looking forward to seeing you.
            The TOUR Championship promises to be time well spent and we’re glad you’ve decided to join us. 
            Safe travels!</p>
            <span className='see-soon-weather'>
              <img src={weather}></img>
              <a target="_blank" href="https://weather.com/weather/tenday/l/64a773851918c96a757386ed180890d92b0479c984aadc89fc380ed87d75c0c2">
                <p className='see-soon-subtitle forecast' >Atlanta 10 Day Forecast</p ></a>
            </span>
          </div>
        </div>

        {/* Weekend Happenings */}
        <div className='info-row row' id="weekend-app">
          <span className='dontmiss-semi'>Don’t miss important updates!</span>
          <span className='make-sure'>Follow these instructions below to stay in the know of all the weekend happenings.</span>
          <ul className="list-title">
            <li>Transportation Updates</li>
            <li>Special Guest Apperances</li>
            <li>Autograph Sessions</li>
            <li>Hospitality Highlights</li>
          </ul>
        </div>
  
  
        {/* QR ROw */}
        <div className='box qr-box'>
  
          <div className='row-content' style={{ width: '100%' }}>

            <div className=' qr-row'>
              <span className='qr-left'>
                <div className='qr-subtitle' style={{ textAlign: 'left' }}>Follow these instructions below to stay in the know of all the weekend happenings</div>

                <table>
                  <tbody>
                    <tr>
                      <td className="table-d">Step 1:</td>
                      <td>Download the Cvent app on your mobile device</td>
                    </tr>
                    <tr>
                      <td className="table-d">Step 2:</td>
                      <td>Open the app and type: <span className='semi-bold-purp'>ACCTOURChamp2024</span> (no spaces, case sensitive) in the search field</td>
                    </tr>
                    <tr>
                      <td className="table-d">Step 3:</td>
                      <td>Click the download arrow in the upper right-hand corner</td>
                    </tr>
                    <tr>
                      <td className="table-d">Step 4:</td>
                      <td>Type in your first name, last name, and the email address used when you registered to attend this event</td>
                    </tr>
                    <tr>
                      <td className="table-d">Step 5:</td>
                      <td>Check your email for a verification code to complete your login and access the weekend details</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span className='italic-medium'>Note:</span> This code is good for one use and expires after 24 hours.
              </span>
  
              {mobile ?
                  <div className='qr-switch'>

                  <div className='app-download'>
                    {(mobileType === 'android' || mobileType == 'unspecified') && <a href="https://apps.apple.com/us/app/cvent-events/id1491335576"><img className={'app-icon'} src={android} /></a>}
                    {((mobileType === 'ios' || mobileType == 'unspecified') && <a href="https://apps.apple.com/us/app/cvent-events/id1491335576"><img className={'app-icon'} src={apple} /></a>)}
                  </div>
                </div>
  
  
                : <div className='qr-switch'>
  
                  {/* <img src={qr}></img> */}
                  <img src={phoneType === 'apple' ? apple_appstore : android_appstore} width={'100%'}></img>
  
                  <span className='qr-span'>
                    Google Play
                    <label className="switch">
                      <input type="checkbox" onChange={(event) => changePhone(event.target.checked)} />
                      <span className="slider round"></span>
                    </label>
                    App Store
                  </span>
                  Toggle based on phone type
  
                </div>
              }
  
  
            </div>
  
            {/* <span>OR</span>
    <a href='https://ts.accenture.com/sites/NAMarketHive/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Fts%2Eaccenture%2Ecom%2F%3Aw%3A%2Fr%2Fsites%2FNAMarketHive%2FShared%20Documents%2FGeneral%2F02%20Iconic%20Programs%2FNA%20Business%20Partners%2FSuper%20Bowl%20%2D%20Las%20Vegas%20%2D%202024%2FCommunications%2FCVENT%20App%2FSuper%20Bowl%20LVIII%20%2D%20Download%20Our%20Mobile%20App%2Edocx%3Fd%3Dwecb13331c19c4f24b15d617b07e983b2%26csf%3D1%26web%3D1%26e%3DxneFN8&correlation=9fc6ffa0%2D90b7%2D4000%2Db31f%2Df816d5ac4b02&Type=item&name=c1e880a8%2D7a21%2D4b88%2Dafe4%2Dadbef5a79db4&listItemId=5384&listItemUniqueId=ecb13331%2Dc19c%2D4f24%2Db15d%2D617b07e983b2'>Download Cvent app HERE</a> */}
          </div>
        </div>

        {/* TRANSPORTATION ROW */}
        <br />

        <div className='transportation-section'>
        <div className='title'>Transportation & Parking</div>
        <div className='trans-row row'>
          {/* ACN Tourney Transporation */}
          <div className='trans-container'>
  
            <div className='box trans-box'>
  
              <div className='row-content' style={{ display: 'block' }}>
                <div className='graphik-mid-purp'>Accenture Tournament Transportation</div>
                <ul>
                  <li>Transportation will loop between Four Seasons, Epicurean, and East Lake so that everyone can be picked up at their individual hotels.</li>
                  
                  <li>Continuous transportation to and from the course will begin at 10:00 AM and will run until 30 minutes after the end of play.</li>
                  
                  <li>Should you have questions about the transportation schedule, please contact our team at <span className='graphik-mid'>PGAinfo@accenture.com</span> or Emily Evanoff at<br /> <span className='graphik-mid'>+1 440-724-8848</span>.</li>
                </ul>
              </div>
            </div>
          </div>
            {/* Hotel Accomodation */}
            <div className='trans-container'>
            
            <div className='box trans-box'>

              <div className='row-content' style={{ display: 'block' }}>
              <div className='graphik-mid-purp'>Hotel Accommodation</div>
              <div className='graphik-regular-purp address'>Epicurean Atlanta <br />1117 West Peachtree Street<br /> Atlanta, GA 30309</div>
                <ul>
                  <li>If you are a guest at one of the designated group hotels, a uniformed Accenture greeter will be available in the Epicure Atlanta lobby to warmly welcome and assist you.</li>
                  <li>Room arrangements have been made based on the information you provided during registration if you requested accommodation.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='trans-row row'>
            {/* Alternative Transporation General Parking */}
            <div className='trans-container'>
            
            <div className='box trans-box'>

              <div className='row-content' style={{ display: 'block' }}>
                <div className='graphik-mid-purp'>Alternative Option: Tournament General Parking</div>
                <ul>
                  <li><span className='graphik-mid'>Wednesday-Friday</span>: Georgia World Congress Center, 362 Ivan Allen Jr. Blvd (Yellow Lot and Marshalling Yard Lot)​</li>
                  <li><span className='graphik-mid'>Saturday-Sunday</span>: Summerhill Parking Lots <br />500 Hank Aaron Dr. SW</li>
                </ul>
                <span className="graphik-italic-regular">* A general parking pass is required</span>
              </div>
            </div>
          </div>
          {/* Rideshare */}
          <div className='trans-container'>
  
            <div className='box trans-box'>
  
              <div className='row-content' style={{ display: 'block' }}>
                <div className='graphik-mid-purp'>Alternative Option: Rideshare</div>
                
                <ul>
                <li>Enter <span className="graphik-mid">“TOUR Championship”</span> as the destination for designated drop-off and pick-up at the Drew Charter School in the Metro Atlanta Area.</li>
                <li>The designated area is located across the street from the 13th Fairway and Rideshare Gate.</li>
                <li>Fans can rest in the climate controlled Rideshare Lounge while waiting for their ride home.</li>
                </ul>
              </div>
            </div>
          </div>


        </div>
        </div>


        <div className='button-row'>
          <button onClick={() => {document.getElementById("weekend-app").scrollIntoView({ behavior: "smooth"})}}>Download the CVENT App</button>
        </div>
        <div className='title tour-title cvent-change'>Tournament Weekend at-a-Glance</div>

        <div className='tournament-weekend'>
          <div className='schedule-box'>
          <div className='schedule-subtitle'>Thursday, August 29 - Sunday, September 1</div>
            <span className='schedule-semibold'>8:00 AM – 6:00 PM </span>
            <br />
            <ul id="weekend-ul">
            <li>Full access to Accenture’s private hospitality suites on the 9th and 18th Green at East Lake</li>
            <li>Food and beverage service available throughout the day</li>
            <li>You will also have access to the 16th Green, a shaded, open-air area that provides select complimentary refreshments and offers menu items for purchase</li>
            </ul>

          </div>

          <div className='schedule-box'>
            <div className='schedule-subtitle'>Thursday, August 29</div>
            <span className='schedule-semibold'>10:00 AM - 6:00 PM | </span>
            <span className='schedule-semibold-it'>Hospitality + Clubhouse Access</span><br />
            <span className='schedule-addy'>9 Green, 16 Green, 18 Green</span>
            <br /> <br />
            <span className='schedule-semibold'>10:50 - 11:05 AM | </span>
            <span className='schedule-semibold-it'>First Tee Hope Tee Shot, </span>
            <span className='graphik-italic-regular'>sponsored by Accenture </span><br />
            <span className='schedule-addy'>1 Tee</span>
            
            <br />
          </div>

          <div className='schedule-box'>
            <div className='schedule-subtitle'>Friday, August 30</div>
            <span className='schedule-semibold'>10:00 AM - 6:00 PM | </span>
            <span className='schedule-semibold-it'>Hospitality + Clubhouse Access</span><br />
            <span className='schedule-addy'>9 Green, 16 Green, 18 Green</span>
            <br /> <br />
            <span className='schedule-semibold'>12:00 - 1:30 PM | </span>
            <span className='schedule-semibold-it'>Talk on the Tee with Eastside Golf and Morehouse College</span><br />
            <span className='schedule-addy'>18 Green Hospitality</span> 
            <br /> <br />
            <span className='schedule-semibold'>7:00 PM - 9:00 PM | </span>
            <span className='schedule-semibold-it'>Toast the TOUR Cocktail Reception​ with PGA Pro Jim Furyk</span><br />
            <span className='schedule-addy'>Epicurean "Office Bar"</span> (Dress Code: Casual)

            <br />
          </div>
          <div className='schedule-box'>
            <div className='schedule-subtitle'>Saturday, August 31</div>
            <span className='schedule-semibold'>10:00 AM - 7:00 PM | </span>
            <span className='schedule-semibold-it'>Hospitality + Clubhouse Access</span><br />
            <span className='schedule-addy'>9 Green, 16 Green, 18 Green</span>
            
          </div>
          <div className='schedule-box'>
          <div className='schedule-subtitle'>Sunday, Sept 1</div>
            <span className='schedule-semibold'>10:00 AM - 6:00 PM | </span>
            <span className='schedule-semibold-it'>Hospitality + Clubhouse Access</span><br />
            <span className='schedule-addy'>9 Green, 16 Green, 18 Green</span>
            <br /><br />
            <span className='schedule-semibold'>2:30 PM - 3:30 PM | </span>
            <span className='schedule-semibold-it'>Talk on the Tee with PGA Commissioner, Jay Monahan</span><br />
            <span className='schedule-addy'>18 Green Hospitality</span>
          </div>
        </div>
  
        <div className='course-guide-box'>
          <p>Course Guide</p>
          <p className='underline'><a href="https://www.eastlakegolfclub.com/course/">East Lake Golf Club Guide</a></p>
        </div>
  
        {/* BOTTOM ROW */}
        <div className='bottom-row'>
  
          <div className='health-row row'>
            <div className='title'>Ticket Delivery</div>
              <div className='box health-box'>
                <div className='row-content'>
                  All tournament tickets are digital for the TOUR Championship. You will receive a separate   email from PGA TOUR Tickets (noreply@ticketmaster.com) with instructions on how to   download your ticket(s) for the days requested.
                </div>
              </div>

            <div className='title'>Rules, Restrictions and Etiquette</div>
            <div className='box health-box'>
              <div className='rules'>
              <span className="small-title">Bag Policy</span>
              <ul>
                <li>Opaque bags 6x6x6 and smaller</li>
                <li>Clear bags 12x6x12 and smaller​</li>
                <li>No computers or luggage is permitted​</li>
              </ul>
              <br />
              <span className="small-title">Mobile Device Policy:</span>
              <ul>
                <li>Devices must be silent at all times; flash may not be used​</li>
                <li>Devices may be used to capture video, audio and photos (content) in all areas throughout the tournament week.</li>
                <li>Content may be used for personal purposes only; no commercial use​</li>
                <li>No live streaming or real-time coverage​</li>
                <li>Texting is permitted in all areas​</li>
                <li>Phone calls are allowed in designated areas only​</li>
              </ul>
              <br />
              <span className="small-title">Etiquette:</span>
              <ul>
                <li>If you are near play, please remain quiet​</li>
                <li>Do not ask for autographs or pictures with players on tournament days</li>
              </ul>
              <br />
              <span className="small-title">Prohibited Items:</span>
              <ul>
                <li>No radios/other noise-producing devices​</li>
                <li>No coolers​</li>
                <li>No weapons of any kind​</li>
                <li>No outside beverages or beverage containers​</li>
                <li>No cameras or video cameras</li>
              </ul>

              </div>
            </div>

            <div className='title'>Health & Safety</div>
  
            <div className='box health-box'>
              <div className='row-content'> We continue to rely on government guidelines and input from our healthcare experts to inform our health and safety protocols. Vaccines are not a requirement of attendance, and COVID-19 testing and masking are also not required. If needed, more information will be provided to you as we approach the event. 
              </div>
            </div>
  
            <div className='title'>Contact</div>
            <div className='box health-box'>
              <div className='row-content'>If you need anything, please contact our team at PGAinfo@accenture.com or our on-site liaison, Amelia Johnson, at +1 516-205-1192
              </div>
            </div>
          </div>
  
  
        </div>
  
        <div className='footer-row'>
        </div>
      </div>
    )
}

export default Subpage1;