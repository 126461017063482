import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import chloe from './assets/chloe.jpeg'
import younggolf from './assets/younggolf.png'
import pgatour from './assets/pgatour.png'
import scores from './assets/scores.png'
import atlanta from './assets/atlanta-thumbnail.png'
import './stylesheets/VideoCarousel.css'
import ReactPlayer from 'react-player';
import { useState, useRef } from 'react';
import play from './assets/play.svg'
import pause from './assets/pause.svg'
import golfdigest from './assets/Thumbnail_GolfDigest.jpg'
// import unmute from './assets/videos/Frame 3.svg'

// import manishVid from './assets/videos/manish.mp4';
// import vega from './assets/videos/vega.mp4';


const videos = [
    { url: '/videos/chloe.mp4', thumbnail: chloe },
    { url: 'https://youtu.be/oCx1pL7OGoY', thumbnail: atlanta },
    { url: 'https://www.youtube.com/watch?v=_KgbYrKopYs', thumbnail: younggolf },
    // Add more videos as needed
];
function VideoCarousel() {
    const [videoLink, setVideoLink] = useState(videos[0].url);

    const videoPlayerRef = useRef(null);
    let currentIndex = 0;
    const firstVids = ['/videos/manish.mp4', '/videos/vega.mp4'];

    const handleVideoEnd = () => {
        console.log('video ended')
        console.log(videoPlayerRef)
        setVideoLink(firstVids[1]);

        // Start playing the new video
        videoPlayerRef.current.seekTo(0); // Seek to the beginning
        videoPlayerRef.current.playing = true;;
        unmuteVid();
    };

    const changeVid = (vid) => {
        console.log(vid);
        setVideoLink(vid)
    }

    const unmuteVid = () => {
        console.log('unmute')
        videoPlayerRef.current.muted = false;
    }

    return (
        <div className='video-carousel-container'>

            <div className='video-carousel-video'>
                {/* <video src={videoLink} autoPlay muted controls width={'100%'} height={'100%'}>
            </video> */}
                <ReactPlayer 
                    ref={videoPlayerRef} 
                    url={videoLink} 
                    controls={true} 
                    width={'100%'} 
                    height={'100%'} 
                    muted={true}
                    playing={true} 
                    playsinline={true}
                    fileConfig={{ attributes: { playsinline: true } }} 
                    onEnded={() => videoLink === videos[0].url ? handleVideoEnd() : '' }
                    // onPlay={() => unmuteVid()}
                />
                {/* <button className="mute-unmute-button" onClick={unmuteVid}>
                    {isMuted ? (
                        <img src={unmute} alt="Unmute" />
                    ) : (
                        ''
                    )}
                </button> */}
            </div>

            <div className='video-carousel-menu'>
                {videos.map((video, i) => {
                    return (<div key={`video-thumbnail-${i}`} className='video-thumbnail' onClick={() => changeVid(video.url)}>
                        <img src={video.thumbnail} className='video-thumbnail-img' />
                        <img src={video.url === videoLink ? pause : play} className='play-pause' />

                    </div>

                    )
                })}
                <div key={`video-thumbnail-4`} className='video-thumbnail' onClick={() => window.open('https://www.golfdigest.com/story/east-lake-golf-club-tour-championship-restoration')} >
                    <img src={golfdigest} className='video-thumbnail-img' />
                </div>
                <div key={`video-thumbnail-5`} className='video-thumbnail' onClick={() => window.open('https://www.pgatour.com/fedexcup')} >
                    <img src={scores} className='video-thumbnail-img' />
                </div>
            </div>

        </div>
    )
}

export default VideoCarousel;