import React, { useEffect, useState } from 'react';
import NavBar from './NavBar'; 
import VideoCarousel from '../VideoCarousel';
import '../stylesheets/App.css';

function PageTemplate({ mobile, mobileType, phoneType, changePhone, children, pagename }) {
    return (
      <div className="App">
        <NavBar isMobile={mobile} pagetype={pagename} />
        <VideoCarousel />
        {children}
        {/* Add common footer or other elements here */}
      </div>
    );
  }
  
  export default PageTemplate;
  