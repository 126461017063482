import './stylesheets/App.css';

import { useEffect, useState } from 'react';

import text from './assets/navbar/text.svg';
import Vector from './assets/navbar/Vector.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import group from './assets/navbar/Group 47.png'
import PageTemplate from './components/pageTemplate';
import Subpage1 from './pages/subpage1'
import Subpage2 from './pages/subpage2'


import amplifyconfig from './amplifyconfiguration.json';
import { Amplify } from "aws-amplify";
import { configureAutoTrack } from 'aws-amplify/analytics';
import LandingPage from './pages/landing';

Amplify.configure(amplifyconfig);

configureAutoTrack({
  enable: true,
  type: 'session',
});


function App() {
  const [mobile, setMobile] = useState(window.innerWidth <= 760);
  const [mobileType, setMobileType] = useState('unspecified');
  const [phoneType, setPhoneType] = useState('android');

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 760);
  };

  const changePhone = (val) => {
    console.log(val);
    setPhoneType(val ? 'apple' : 'android');
  };

  const changePhoneType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setMobileType('android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setMobileType('ios');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    changePhoneType();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Router>
      <Routes>
            <Route path="/" element={(<LandingPage></LandingPage>)} />
            <Route 
              path="/csuite" 
              element={            
              <PageTemplate
                mobile={mobile}
                pagename={"csuite"}
              >
              <Subpage1 
                  mobile={mobile}
                  mobileType={mobileType}
                  phoneType={phoneType}
                  changePhone={changePhone}
              />
              </PageTemplate>
            } 
            />
            <Route path="/ute" 
              element={            
              <PageTemplate
                mobile={mobile}
                pagename={"ute"}
              >
                <Subpage2 
                  mobile={mobile}
                  mobileType={mobileType}
                  phoneType={phoneType}
                  changePhone={changePhone}
                />
              </PageTemplate>
            }             
            />
      </Routes>

    </Router>
  );
}

export default App;
